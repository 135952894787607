<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.type.name.$errors.length }"
                        :label="$t('forms.name')"
                        label-for="name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="type.name" id="name" type="text"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.type.name.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
    props: {
        typeObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            type: {
                name: {required}
            },
        };
    },
    data() {
        return {
            type: this.typeObject,
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.action === "add") {
                    this.create(
                        this.$StockItemTypes,
                        this.type,
                        this.$t("messages.stockItemTypeCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$StockItemTypes,
                        this.type.id,
                        this.type,
                        this.$t("messages.stockItemTypeUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/stock-item-types`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
