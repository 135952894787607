import enumsEn from '@/locales/enum-en.json'

export default {
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
        }
    },
    methods: {
        getEnumList(names, ...lists) {
            let enums = enumsEn
            if (!(names instanceof Array)) {
                names = [names]
            }
            this.$Enums.getCollection({params: {pagination: false, cache: true}})
                .then(response => {
                    names.forEach((name, index) => {
                        response.data['hydra:member'].forEach(element => {
                            if (name === element.name) {
                                let exists = JSON.stringify(enums).includes(name)
                                element.keys.forEach(key => {
                                    lists[index].push({
                                        label: exists ? enums[name][key] : key,
                                        id: key,
                                    })
                                })
                            }
                        })
                    })
                })
        },
        getUserList(args, ...lists) {
            const {params} = args

            params.pagination = false
            params.enabled = true;
            if (!(params.role instanceof Array)) {
                params.role = [params.role]
            }

            this.$Users.getCollection({params}, 'user_list')
                .then(response => {
                    response.data['hydra:member'].forEach(element => {
                        params.role.forEach((role, index) => {
                            if (role === element.role) {
                                let label = `${element.givenName} ${element.familyName}`
                                if (role === "ROLE_CUSTOMER") {
                                    label = element.customer
                                }
                                lists[index].push({
                                    label,
                                    id: element['@id'],
                                    numberId: element.id,
                                })
                            }
                        })
                    })
                })
        },
        getResourceList(resource, list, params = {}) {
            params.pagination = false
            resource.getCollection({params}, "id_name")
                .then(response => {
                    response.data['hydra:member'].forEach(element => {
                        list.push({
                            label: `${element.name}`,
                            id: element['@id'],
                            numberId: element.id,
                        })
                    })
                })
        },
        getServiceRequestList(resource, list, params = {}) {
            params.pagination = false
            resource.getCollection({params}, "id_name")
                .then(response => {
                    response.data['hydra:member'].forEach(element => {
                        list.push({
                            label: `${element.name}`,
                            description: element.description,
                            id: element['@id'],
                            numberId: element.id,
                        })
                    })
                })
        },
    },
}
